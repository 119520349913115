<template>
  <div>
    <bread-crumb></bread-crumb>
    <div class="error-page container container__mini2">
      <h2>Сторінка не знайдена</h2>
      <router-link to="/">Перейти на головну сторінку</router-link>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const BreadCrumb = defineAsyncComponent(() =>
  import("@/components/BreadCrumb.vue")
);

export default {
  components: {
    BreadCrumb,
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.error-page {
  @include property("margin-bottom", 100, 30);
  h2 {
    font-weight: 700;
    @include property("font-size", 32, 24);
    margin-bottom: 1em;
  }
}
</style>